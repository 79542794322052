import {
  AddProductMajor,
  ProductsMajor,
  AnalyticsMajor,
  CheckoutMajor,
  SettingsMajor
} from '@shopify/polaris-icons';

export const navigationValues = () => {
  return [
    {
      name: 'navigation.overview',
      path: '/',
      icon: AnalyticsMajor
    },
    {
      name: 'navigation.add_product',
      path: '/listings',

      icon: AddProductMajor
    },
    {
      name: 'navigation.my_products',
      path: '/products',
      icon: ProductsMajor
    },
    {
      name: 'navigation.my_orders',
      path: '/orders',
      icon: CheckoutMajor
    },
    {
      name: 'navigation.reports',
      path: '/reports',
      icon: CheckoutMajor
    }
  ];
};

// this will eventually have all drop down items
// then will make into an array
export const settings = {
  name: 'navigation.settings_nav_name',
  path: '/settings',
  icon: SettingsMajor
};

export const avatarDropDownValues = [
  {
    name: 'navigation.account_settings',
    path: '/settings',
    alt: 'Account Setting',
    icon: '/assets/icons/profile-icon.svg'
  },
  {
    name: 'navigation.help_center',
    path: 'https://help.spocket.co/spocket-for-suppliers-makers',
    alt: 'Help Center',
    icon: '/assets/icons/info-icon.svg'
  },
  {
    name: 'navigation.logout',
    path: '/login',
    alt: 'Logout',
    icon: '/assets/icons/log-out-icon.svg'
  }
];

export const ROWS_PER_PAGE = 24;

export const messageTypes = {
  NO_PRODUCTS_UPLOADED: 'NO_PRODUCTS_UPLOADED',
  NO_PRODUCTS_FOUND: 'NO_PRODUCTS_FOUND',
  NO_ORDERS_UPLOADED: 'NO_ORDERS_UPLOADED',
  NO_ORDERS_FOUND: 'NO_ORDERS_FOUND',
  NO_ORDER_FOUND: 'NO_ORDER_FOUND'
};

export const ALL_PRODUCTS_ACTIVATED = 'Successfully activated all listings';
export const SOME_PRODUCTS_ACTIVATED = 'Successfully activated some listings';
export const ALL_PRODUCTS_UPDATED = 'Successfully updated all listings';

export const deliveryStatusOptions = (t) => {
  return {
    not_found: t('filters.status_not_found'),
    in_transit: t('filters.status_in_transit'),
    delivered: t('filters.status_delivered'),
    undelivered: t('filters.status_undelivered')
  };
};

const ORDER_PLACED = 'ORDER_PLACED';
const BEGIN_FULFILLMENT = 'BEGIN_FULFILLMENT';
const ATTACH_INVOICE = 'ATTACH_INVOICE';
const ADD_TRACKING = 'ADD_TRACKING';
const FULFILLED = 'FULFILLED';
const IN_TRANSIT = 'IN_TRANSIT';
const DELIVERED = 'DELIVERED';

export const ORDER_STATUS = {
  ORDER_PLACED,
  BEGIN_FULFILLMENT,
  ATTACH_INVOICE,
  ADD_TRACKING,
  FULFILLED,
  IN_TRANSIT,
  DELIVERED
};

export const STATUS_TOOLTIPS = {
  ORDER_PLACED: {
    completeTooltip: 'status_tooltip.order_placed_complete'
  },
  BEGIN_FULFILLMENT: {
    completeTooltip: 'status_tooltip.begin_fulfillment_complete',
    activeTooltip: 'status_tooltip.begin_fulfillment_active'
  },
  ATTACH_INVOICE: {
    completeTooltip: 'status_tooltip.attach_invoice_complete',
    activeTooltip: 'status_tooltip.attach_invoice_active'
  },
  ADD_TRACKING: {
    completeTooltip: 'status_tooltip.tracking_info_complete',
    activeTooltip: 'status_tooltip.tracking_info_active'
  },
  FULFILLED: {
    completeTooltip: 'status_tooltip.order_fulfilled_complete'
  },
  IN_TRANSIT: {
    completeTooltip: 'status_tooltip.in_transit_complete'
  },
  DELIVERED: {
    completeTooltip: 'status_tooltip.delivered_complete'
  }
};

export const playlistResources = [
  {
    title: 'tutorial_playlist.first_video_title',
    videoSrc: 'https://www.youtube.com/embed/doybDZnywxM',
    videoId: 'first-video'
  },
  {
    title: 'tutorial_playlist.second_video_title',
    videoSrc: 'https://www.youtube.com/embed/Jtx5SrUK_9A',
    videoId: 'second-video'
  },
  {
    title: 'tutorial_playlist.third_video_title',
    videoSrc: 'https://www.youtube.com/embed/LSolpuJCDm4',
    videoId: 'third-video'
  },
  {
    title: 'tutorial_playlist.fourth_video_title',
    videoSrc: 'https://www.youtube.com/embed/lNC2ZeTzerY',
    videoId: 'fourth-video'
  }
];

export const bookmarksResources = [
  {
    imageSrc: 'assets/icons/help-center-icon.svg',
    imageAlt: 'help center icon',
    link: 'https://help.spocket.co/en/collections/316458-spocket-for-suppliers',
    linkId: 'help-center-icon',
    title: 'help_center_title',
    description: 'help_center_description'
  },
  {
    imageSrc: 'assets/icons/community-icon.svg',
    imageAlt: 'community icon',
    link: 'https://bit.ly/FBGroupDSDashaboard',
    linkId: 'community-icon',
    title: 'community_title',
    description: 'community_description'
  },
  {
    imageSrc: 'assets/icons/blog-icon.svg',
    imageAlt: 'blog icon',
    link: 'https://bit.ly/MediumDSDashboard',
    linkId: 'blog-icon',
    title: 'blog_title',
    description: 'blog_description'
  }
];

export const returnPolicyOptions = [
  { name: 'refund.policy_option_no_returns', value: '0' },
  { name: 'refund.policy_option_fifteen_days_returns', value: '15' },
  { name: 'refund.policy_option_thirty_days_returns', value: '30' }
];

export const returnPolicyDescriptions = [
  { name: 'refund.policy_description_option_no_returns', value: '0' },
  {
    name: 'refund.policy_description_option_fifteen_days_returns',
    value: '15'
  },
  {
    name: 'refund.policy_description_option_thirty_days_returns',
    value: '30'
  }
];

export const processingWindows = [
  { value: '1-2', name: '1-2 days' },
  { value: '2-3', name: '2-3 days' },
  { value: '3-5', name: '3-5 days' },
  { value: '5-7', name: '5-7 days' },
  { value: '7-10', name: '7-10 days' }
];

export const domesticShippingTime = [
  { name: '1-3 days', value: '1-3' },
  { name: '4-7  days', value: '4-7' },
  { name: '8-14 days', value: '8-14' }
];

export const internationalShippingTime = [
  ...domesticShippingTime,
  { name: '15-21 days', value: '15-21' },
  { name: '15-30 days', value: '15-30' }
];

const PAYPAL_ERROR_ACCOUNT_LIMITED = 'Your account has been limited by Paypal';
const PAYPAL_ERROR_CONFIRM_EMAIL =
  'Please confirm your Paypal email before start accepting Payments';
const PAYPAL_ERROR_APP_NOT_AUTHORIZED =
  'Spocket App not authorized, Go through Onboarding flow again and grant party Permissions to Spocket';

export const paypalErrors = [
  {
    errorString: PAYPAL_ERROR_ACCOUNT_LIMITED,
    title: 'getpaid.paypal_error_account_limited_title',
    content: 'getpaid.paypal_error_account_limited_content'
  },
  {
    errorString: PAYPAL_ERROR_CONFIRM_EMAIL,
    title: 'getpaid.paypal_error_confirm_email_title',
    content: 'getpaid.paypal_error_confirm_email_content'
  },
  {
    errorString: PAYPAL_ERROR_APP_NOT_AUTHORIZED,
    title: 'getpaid.paypal_error_not_authorized_title',
    content: 'getpaid.paypal_error_not_authorized_content'
  }
];
